iframe {
  display: none !important;
}

/* START: FONTS */

/* START: Acronym */
@font-face {
  font-family: 'Acronym';
  src: url('./static/fonts/Acronym-Light.woff2') format('woff2'),
    url('./static/fonts/Acronym-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Acronym';
  src: url('./static/fonts/Acronym-Regular.woff2') format('woff2'),
    url('./static/fonts/Acronym-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Acronym';
  src: url('./static/fonts/Acronym-Semibold.woff2') format('woff2'),
    url('./static/fonts/Acronym-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
/* END: Acronym */

/* END: FONTS */

/* Styles */

p {
  font-size: 16px;
  line-height: 1.4;
}

body .MuiDialog-paperScrollPaper {
  max-height: calc(100vh - 90px);
  margin-top: 90px;
}
